import React from 'react'
import classes from './learners.module.css'
import { useNavigate } from 'react-router-dom'

import InfoCard from '../../components/ui/infoCard/infoCard'
import SideCard from '../../components/ui/sideCard/sideCard'
import Container from '../../components/ui/container/container'
import Button from '../../components/ui/button/button'
import TextCard from '../../components/ui/textCard/textCard'
import ScrollDownBtn from '../../components/ui/scrollDownBtn/scrollDownBtn'

import {TbManualGearbox} from 'react-icons/tb'
import {VscSmiley} from 'react-icons/vsc'
import {IoLanguageSharp} from 'react-icons/io5'
import {AiOutlineSafetyCertificate} from 'react-icons/ai'
import {FiRefreshCw} from 'react-icons/fi'
import {BsShieldPlus, BsStack} from 'react-icons/bs'
import {GiBlackBook} from 'react-icons/gi'
import {FaRoad} from 'react-icons/fa'

function Learners(){

  let navigate = useNavigate(); 
  const goToContact = () =>{ 
    let path = '/Contact-Us'; 
    navigate(path);
  }

  return (
    <div>
      <section className={classes.heroSection} id='section1'>
        <div className={classes.heroText}>
          <h1>Manual & Automatic.</h1>
          <p>The best & up-to-date driving lessons, provided by approved driving instructors.</p>
          <Button click={goToContact} color='var(--red-accent)'>Book Now</Button>
          <ScrollDownBtn section='#section2'/>
        </div>
      </section>

      <Container>
        <section className={classes.infoSection} id='section2'>
          <section className={classes.contentSection}>
            <h4>Develop your driving skills with us.</h4>
            
            <TextCard>
              <div className={classes.grid1}>
                <div className={classes.grid1a}>
                  <SideCard>
                    <TbManualGearbox size={50}/>
                    <p>Manual and Automatic Lessons</p>
                  </SideCard>

                  <SideCard>
                    <FiRefreshCw size={50}/>
                    <p>Refresher Lessons</p>
                  </SideCard>

                  <SideCard>
                    <FaRoad size={50}/>
                    <p>Motorway Lessons</p>
                  </SideCard>

                  <SideCard>
                    <BsShieldPlus size={50}/>
                    <p>Pass Plus Lessons</p>
                  </SideCard>
                  
                  <SideCard>
                    <BsStack size={50}/>
                    <p>Block Booking</p>
                  </SideCard>

                  <SideCard>
                    <GiBlackBook size={50}/>
                    <p>Theory Test Support</p>
                  </SideCard>

                  <Button className={classes.button} click={goToContact} color='var(--red-accent)'>
                    Contact us now
                  </Button>
                </div>
              </div>
            </TextCard>
          </section>

          <section className={classes.contentSection}>
            <h4>Benefits of learning with us.</h4>
            <div className={classes.grid2}>
              <InfoCard color='var(--red-accent)'>
                <VscSmiley size={88}/>
                <p>We build confidence in our students, through the exposure of different driving situations.</p>
              </InfoCard>

              <InfoCard color='var(--green-main)'>
                <AiOutlineSafetyCertificate size={88}/>
                <p>We believe in delivering the best driving tuition, bundled with a friendly atmosphere, which will bring out the best in you.</p>
              </InfoCard>

              <InfoCard color='var(--blue-main)'>
                <IoLanguageSharp size={88}/>
                <p>Our multicultural team can teach in: English, Gujarati, Hindi, and Punjabi.</p>
              </InfoCard>
            </div>
          </section>
        </section>
      </Container>
    </div>
  )
}

export default Learners;