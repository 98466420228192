import React from 'react'
import classes from './contact.module.css'

import TextCard from '../../components/ui/textCard/textCard'
import Container from '../../components/ui/container/container'
import SideCard from '../../components/ui/sideCard/sideCard'
import ScrollDownBtn from '../../components/ui/scrollDownBtn/scrollDownBtn'
import EmailForm from '../../components/ui/emailForm/emailForm'
import Button from '../../components/ui/button/button'

import {FaFacebookSquare, FaInstagram, FaPhoneAlt, FaWhatsapp, FaMapMarkerAlt} from 'react-icons/fa'

const Contact = () => {

  function callNumber(){
    document.location.href = 'tel: +447512414566'
  }

  return (
    <div>
      <section className={classes.heroSection} id='section1'>
        <div className={classes.heroText}>
          <h1>Contact Us Now.</h1>
          <p>Want to know more, or have any queries?</p>
          <Button color='var(--red-accent)' href='#section2'>
            <a href='#section2' style={{textDecoration:'none', color:'var(--light-text-color)', width:'100%'}}>
              Go To Message Form
            </a>
          </Button>
          <Button color='var(--green-main)' click={callNumber}>
            Call Now On : 07512414566
          </Button>
          <ScrollDownBtn section='#section2'/>
        </div>
      </section>

      <Container>
        <section className={classes.infoSection} id='section2'>
          <section className={classes.contentSection}>
            <EmailForm/>
          </section>

          <section className={classes.contentSection}>
            <h4>Check out our socials or contact us using the following.</h4>
              <TextCard>
                <div className={classes.contactsLayout}>
                  <div className={classes.contactsBlock}>
                    <h5>Socials</h5>
                    <SideCard>
                      <FaFacebookSquare size={33}/>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/www.unit10drivertrainingcollege.co.uk/'>
                        Unit 10 Driver Training College
                      </a>
                    </SideCard>

                    <SideCard>
                      <FaInstagram size={33}/>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/unit10drivertraining/?hl=en'>
                        Unit 10 Driver Training College
                      </a>
                    </SideCard>

                    <SideCard>
                      <FaWhatsapp size={33}/>
                      <a target='_blank' rel='noopener noreferrer' href='https://wa.me/447512414566'>
                        Send A Whatsapp Message
                      </a>
                    </SideCard>
                  </div>

                  <div className={classes.contactsBlock}>
                    <h5>Contacts</h5>
                    <SideCard>
                      <FaMapMarkerAlt size={33}/>
                      <p>Leicester Based</p>
                    </SideCard>
                    
                    <SideCard>
                      <FaPhoneAlt size={33}/>
                      <a href='tel: +447512414566'>0751 241 4566</a>
                    </SideCard>
                  </div>
                </div>
              </TextCard> 
          </section>
        </section>
      </Container>
    </div>
  )
}

export default Contact;