import React from 'react'
import classes from './instructors.module.css'
import { useNavigate } from 'react-router-dom'

import InfoCard from '../../components/ui/infoCard/infoCard'
import SideCard from '../../components/ui/sideCard/sideCard'
import Container from '../../components/ui/container/container'
import Button from '../../components/ui/button/button'
import TextCard from '../../components/ui/textCard/textCard'
import ScrollDownBtn from '../../components/ui/scrollDownBtn/scrollDownBtn'

import {AiOutlineRead} from 'react-icons/ai'
import {BsPeople} from 'react-icons/bs'
import {RiGuideLine} from 'react-icons/ri'
import {GiMoneyStack} from 'react-icons/gi'
import {FaChalkboardTeacher,FaHandsHelping} from 'react-icons/fa'

function Instructors(){

  let navigate = useNavigate(); 
  const goToContact = () =>{ 
    let path = '/Contact-Us'; 
    navigate(path);
  }

  return (
    <div>
      <section className={classes.heroSection} id='section1'>
        <div className={classes.heroText}>
          <h1>Join the team.</h1>
          <p>One-to-one training and full support provided.</p>
          <Button click={goToContact} color='var(--red-accent)'>Book Now</Button>
          <ScrollDownBtn section='#section2'/>
        </div>
      </section>

      <Container>
        <section className={classes.infoSection} id='section2'>
          <section className={classes.contentSection}>
            <h4>Want to become a driving instructor? You are in the right place!</h4>
            
            <TextCard>
              <div className={classes.grid1}>
                <div className={classes.grid1a}>
                  <SideCard>
                    <AiOutlineRead size={50}/>
                    <p>Train with us and become a driving instructor</p>
                  </SideCard>

                  <SideCard>
                    <FaHandsHelping size={50}/>
                    <p>Get help for your ADI standard check test</p>
                  </SideCard>

                  <SideCard>
                    <BsPeople size={50}/>
                    <p>Train others to become driving instructors</p>
                  </SideCard>

                  <Button className={classes.button} click={goToContact} color='var(--red-accent)'>
                    Contact us now
                  </Button>
                </div>
              </div>
            </TextCard>
          </section>

          <section className={classes.contentSection}>
            <h4>Benefits of becoming a driving instructor with us.</h4>
            <div className={classes.grid2}>
              <InfoCard color='var(--red-accent)'>
                <RiGuideLine size={88}/>
                <p>We know our guidance and training will open new opportunities for you.</p>
              </InfoCard>

              <InfoCard color='var(--green-main)'>
                <GiMoneyStack size={88}/>
                <p>Be your own boss. Work flexible hours. Make a solid income (£35,000 +).</p>
              </InfoCard>

              <InfoCard color='var(--blue-main)'>
                <FaChalkboardTeacher size={88}/>
                <p>Receive one-to-one training and guidance. All for a low course fee.</p>
              </InfoCard>
            </div>
          </section>

          <section className={classes.contentSection}>
            <h4>How to become a qualified driving instructor.</h4>
            <div className={classes.grid3}>
              <div style={{gridColumn:'span 2'}} >
                <TextCard>
                  <h4 style={{fontWeight:'1000', color:'var(--red-accent)'}}>
                    We can guide you through the whole DVSA ADI examination process.
                  </h4>
                </TextCard>
              </div>

              <TextCard>
                  <h5>Requirements</h5>
                  <p>You must meet the following requirements:</p>
                  <ul style={{marginLeft:'5%'}}>
                    <li>Age of 21 or over</li>
                    <li>Need a full UK driving license for over 3 years</li>
                    <li>Must have less than 6 penalty points on driving license</li>
                    <li>And most importantly must enjoy working with and teaching people</li>
                  </ul>
              </TextCard>

              <TextCard>
                <h5>Step 1 - Theory & Hazard Perception</h5>
                <p>100 Multiple choice questions on 4 sections:</p>
                <ul style={{marginLeft:'7%'}}>
                  <li>Road Procedure</li>
                  <li>Traffic signs and signals, car control, pedestrians and mechanical knowledge</li>
                  <li>Driving Test, disabilities, and the law</li>
                  <li>Publications and instructional technique</li>
                </ul>
                <p>You must get 20/25 marks in each section.</p>
                <p>For the Hazard Perception section you will be shown a series of 14 clips. Each clip has a series of potential hazards. You must click to identify you have seen a potential hazard.</p>
                <p>You must get 57/75 marks in this section.</p>
              </TextCard>

              <TextCard>
                <h5>Step 2 - Driving Ability Test</h5>
                <p>In this test you must demonstrate the following:</p>
                <ul style={{marginLeft:'5%'}}>
                  <li>Expert handling of car controls</li>
                  <li>Use of correct road procedure</li>
                  <li>Anticipation of actions of other road users and then taking appropriate action</li>
                  <li>Sound judgement of distance, speed and timing</li>
                  <li>Consideration for the convenience and safety of other road users</li>
                  <li>Driving in an environmentally-friendly manner</li>
                </ul>
                <p>You will drive on various road and traffic conditions, including motorways & dual carriageways where possible.</p>
              </TextCard>

              <TextCard>
                <h5>Step 3 - Instructional Ability Test</h5>
                <p>You will be marked on 17 areas of competence that are grouped in the following 3 categories:</p>
                <ul style={{marginLeft:'5%'}}>
                  <li>Lesson planning</li>
                  <li>Risk Management</li>
                  <li>Teaching and learning strategies</li>
                </ul>
                <p>The 17 areas of competence are listed in the ADI part 3 test report form, which the examiner will fill in at the end of your test.</p>
                <p>You will get a score from 0 to 3 for each of the 17 competencies, which are added up to work out if you have passed the test, and what your grade will be.</p>
              </TextCard>

              <div style={{gridColumn:'span 2'}}>
                <TextCard>
                  <p style={{margin:'0'}}>The time it takes to qualify as a Driving Instructor is dependent on your availability and
                      subject to the DVSA’s examination schedules. There are three parts to the qualifying
                      examination (as mentioned above). Each part has to be taken consecutively and each will
                      carry with it a six-weeks waiting period (sometimes longer). Consequently, the exam process
                      itself from start to finish is about 18 weeks, assuming there are no other delays. This is why
                      intensive training for Part 1, 2 or 3 is unsuitable and is advised to spread out over several
                      weeks on a part-time basis where the training will consist of both theory and practical.
                  </p>
                </TextCard>
              </div>
            </div>
            <Button color='var(--red-accent)' click={goToContact}>
              <p>Contact us now</p>
            </Button>
          </section>
        </section>
      </Container>
    </div>
  )
}

export default Instructors;