import React from 'react'
import classes from './adi.module.css'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/ui/button/button'
import Container from '../../components/ui/container/container'
import TextCard from '../../components/ui/textCard/textCard'
import ScrollDownBtn from '../../components/ui/scrollDownBtn/scrollDownBtn'

function Adi(){

  let navigate = useNavigate(); 
  const goToContact = () =>{ 
    let path = '/Contact-Us'; 
    navigate(path);
  }

  return (
    <div>
      <section className={classes.heroSection} id='section1'>
        <div className={classes.heroText}>
          <h1>Get Help For Your ADI Standard Check Test.</h1>
          <p>We provide valuable guidance and support to ace your test.</p>
          <Button click={goToContact} color='var(--red-accent)'>Book Now</Button>
          <ScrollDownBtn section='#section2'/>
        </div>
      </section>

      <Container>
        <section className={classes.infoSection} id='section2'>
          <section className={classes.contentSection}>
            <h4>Common faults that lead to unsuccessful tests.</h4>
            <TextCard>
                <h5>3 Main reasons why ADI's are unsuccessful in the standard check test:</h5>
                <ol style={{display:'flex', flexFlow:'column', gap:'1rem'}}>
                    <li>Lesson plan is not adapted to match the learning goals of the pupil.</li>
                    <li>Teaching style is not adapted to suit the pupil's learning style.</li>
                    <li>ADI does not ensure that the pupil fully understands how the responsibility of risky situations are to be shared.</li>
                </ol>
            </TextCard>
          </section>

          <section className={classes.contentSection}>
            <h4>How we can help.</h4>
            <TextCard>
                <p>We can show you practical teaching solutions to help you put the 17 competencies into practice. We will help you become more confident in your instructing abilities.</p>
            </TextCard>
            
            <TextCard>
                <p>Book an assessment session today!</p>
                <p>Ideally with the leaner you intend to take your ADI Standard Check Test with.</p>
            </TextCard>

            <TextCard>
                <h5>3 Benefits of training with a learner you are comfortable with:</h5>
                <ol style={{display:'flex', flexFlow:'column', gap:'1rem'}}>
                    <li>Gives us the best analysis of how you are currently putting the 17 competencies into practice.</li>
                    <li>You will get used to teaching while there is someone in the back of your vehicle, who is analysing your actions.</li>
                    <li>The learner will get used to having someone in the back of the car, so that they can perform their best and save you hassle.</li>
                </ol>
            </TextCard>

            <Button click={goToContact} color='var(--red-accent)'>
                Book an assessment today
            </Button>
          </section>
        </section>
      </Container>
    </div>
  )
}

export default Adi;