import React, { Component } from 'react';
import './App.css';

import { Route, Routes } from 'react-router-dom'

import Layout from './layout/layout';
import Home from './pages/home/home';
import Instructors from './pages/instructors/instructors';
import Learners from './pages/learners/learners';
import Contact from './pages/contact/contact';
import Adi from './pages/adi/adi';

class App extends Component {
  render() {
    let routes = (
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/Driving-Lessons' element={<Learners/>}/>
        <Route path='/Become-A-Driving-Instructor' element={<Instructors/>}/>
        <Route path='/ADI-Test-Support' element={<Adi/>}/>
        <Route path='/Contact-Us' element={<Contact/>}/>
      </Routes>
    )

  return(
    <div>
      <Layout>
        {routes}
      </Layout>
    </div>
  )
  }
}

export default (App);

