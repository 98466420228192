import React from 'react'
import classes from './footer.module.css'

import Container from '../../ui/container/container'
import SideCard from '../../ui/sideCard/sideCard'
import Footlink from './footlink/footlink'
import { CURRENT_YEAR } from '../../../config'

import {FaPhoneAlt, FaWhatsapp, FaMapMarkerAlt, FaFacebookSquare, FaInstagram} from 'react-icons/fa'

const footer = () => {
  return (
    <footer className={classes.footer}>
      <Container>
        <div className={classes.footerLayout}>
          <div className={classes.footerBlock}>
            <h5 style={{color:'var(--red-accent)'}}>Quick Links</h5>
            <ul className={classes.footlinks}>
              <Footlink link='/'>Home</Footlink>
              <Footlink link='/Driving-Lessons'>Driving Lessons</Footlink>
              <Footlink link='/Become-A-Driving-Instructor'>Driving Instructors</Footlink>
              <Footlink link='ADI-Test-Support'>ADI Test Support</Footlink>
              <Footlink link='/Contact-Us'>Contact Us</Footlink>
              <a className={classes.customLink} target='_blank' rel='noopener noreferrer' href='https://www.gov.uk/government/organisations/driver-and-vehicle-licensing-agency'>
                DVLA Home
              </a>
            </ul>
          </div>

          <div className={classes.footerBlock}>
            <h5 style={{color:'var(--red-accent)'}}>Quick Contacts</h5>
            <div className={classes.contactLinks}>
              <SideCard>
                <FaMapMarkerAlt size={22}/>
                <p>Leicester Based</p>
              </SideCard>

              <SideCard>
                <FaPhoneAlt size={22}/>
                <a href='tel: +447512414566'>0751 241 4566</a>
              </SideCard>

              <SideCard>
                <FaFacebookSquare size={22}/>
                <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/www.unit10drivertrainingcollege.co.uk/'>
                  Unit 10 @ Facebook
                </a>
              </SideCard>

              <SideCard>
                <FaInstagram size={22}/>
                <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/unit10drivertraining/?hl=en'>
                  Unit 10 @ Instagram
                </a>
              </SideCard>

              <SideCard>
                <FaWhatsapp size={22}/>
                <a target='_blank' rel='noopener noreferrer' href='https://wa.me/447512414566'>
                  Whatsapp Now
                </a>
              </SideCard>
            </div>
          </div>

      
          <div className={classes.map}>
            <iframe title='find us' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d154964.22701331548!2d-1.271181964453245!3d52.6362028652753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487742ab49b76c73%3A0x9a151d2a6fb49cb8!2sLeicester!5e0!3m2!1sen!2suk!4v1661814130971!5m2!1sen!2suk" width="100%" height="100%" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>

        <div className={classes.rights}>
          <p>@ Unit 10 Driver Training College {CURRENT_YEAR} All Rights Reserved</p>
          <p>Developed By Varshil Patel</p>
        </div>
      </Container>
    </footer>
  )
}

export default footer