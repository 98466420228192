import React from 'react'
import classes from './button.module.css'

const button = (props) => {
  return (
    <button className={classes.button} onClick={props.click} style={{backgroundColor:props.color, borderColor: props.color}}>
      {props.children}
    </button>
  )
}

export default button