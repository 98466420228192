import React from 'react'
import classes from './footlink.module.css'
import { NavLink } from 'react-router-dom'

const footlink = (props) => {
  return (
    <li className={classes.defaultLink}>
      <NavLink to={props.link} className={({ isActive }) => (isActive ? classes.active : '')}>
        {props.children}
      </NavLink>
    </li>
  )
}

export default footlink