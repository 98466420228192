import React from 'react'
import classes from './sideCard.module.css'

const sideCard = (props) => {
  return (
    <div className={classes.sideCard} style={{backgroundColor:props.color}}>
        {props.children}
    </div>
  )
}

export default sideCard