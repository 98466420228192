import React, { useRef } from 'react'
import classes from './emailForm.module.css'

import TextCard from '../textCard/textCard'

const EmailForm = () => {
    const form = useRef();

    return (
        <TextCard>
            <h4>Drop A Message Below</h4>
            <p style={{textAlign:'center'}}>We will get back to you as soon as possible.</p>
            <form ref={form} className={classes.form} action='https://formsubmit.co/b077a8a60e720a44ba69466bb5dff2af' method='POST'>
                <div className={classes.detail}>
                    <label>Full Name *</label>
                    <input className={classes.input} type='text' name='Full name' placeholder='eg. John Doe' required/>
                </div>
                
                <div className={classes.detail}>
                    <label>Phone Number *</label>
                    <input className={classes.input} type='integer' name='Phone Number' placeholder='eg. 0751 241 4566' pattern="[0-9]{11}" required/>
                </div>

                <div className={classes.detail}>
                    <label>Email (Optional)</label>
                    <input className={classes.input} type='text' name='Email' placeholder='eg. name@example.com' pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"/>
                </div>

                <div className={classes.detail}>
                    <label>Subject *</label>
                    <select className={classes.input} required>
                        <option value="" default disabled selected hidden>Select A Subject</option>
                        <option value="Manual_Lessons">Manual Lessons</option>
                        <option value="Automatic_Lessons">Automatic Lessons</option>
                        <option value="Become_A_Driving_Instructor">Become A Driving Instructor</option>
                        <option value="ADI_Training">ADI Training/ Standard Check Test</option>
                    </select>
                </div>

                <div className={classes.detail}>
                    <label>Message *</label>
                    <textarea className={classes.input} style={{height:'20vh'}} type='text' name='Message' placeholder='Message' required/>
                </div>

                <div>
                    <button className={classes.button}>Submit Form</button>
                </div>
            </form>
        </TextCard>
    )
}
//manual, auto, adi training,
//redirect to call
export default EmailForm