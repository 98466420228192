import React from 'react'
import classes from './scrollDownBtn.module.css'

import {BsChevronDoubleDown} from 'react-icons/bs'

const scrollDownBtn = (props) => {
  return (
    <a className={classes.scrollDownBtn} href={props.section}><BsChevronDoubleDown color='var(--light-text-color)' size={33}/></a>
  )
}

export default scrollDownBtn