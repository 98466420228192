import React from 'react'
import classes from './navlinks.module.css'

import Navlink from './navlink/navlink'

const navlinks = () => {
  return (
    <ul className={classes.navlinks}>
      <Navlink link='/' uniqueLink={false}>Home</Navlink>
      <Navlink link='/Driving-Lessons' uniqueLink={false}>Driving Lessons</Navlink>
      <Navlink link='/Become-A-Driving-Instructor' uniqueLink={false}>Become A Driving Instructor</Navlink>
      <Navlink link='/ADI-Test-Support' uniqueLink={false}>ADI Standard Check Test Support</Navlink>
      <Navlink link='/Contact-Us' uniqueLink={true}>Contact Us</Navlink>
    </ul>
  )
}

export default navlinks