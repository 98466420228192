import React from 'react'
import classes from './home.module.css'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/ui/button/button'
import Container from '../../components/ui/container/container'
import TextCard from '../../components/ui/textCard/textCard'
import ScrollDownBtn from '../../components/ui/scrollDownBtn/scrollDownBtn'

import {GiGearStick} from 'react-icons/gi'
import {FaAddressCard, FaMapMarkerAlt, FaPhoneAlt} from 'react-icons/fa'

function Home(){

  let navigate = useNavigate(); 
  const goToContact = () =>{ 
    let path = '/Contact-Us'; 
    navigate(path);
  }

  const goToLearners = () =>{ 
    let path = '/Driving-Lessons'; 
    navigate(path);
  }

  const goToInstructors = () =>{ 
    let path = '/Become-A-Driving-Instructor'; 
    navigate(path);
  }
    
  return (
    <div>
      <section className={classes.heroSection} id='section1'>
        <div className={classes.heroText}>
          <h1>Driver Training College.</h1>
          <p>Train with the best and level up your driving today.</p>
          <Button click={goToContact} color='var(--red-accent)'>Book Now</Button>
          <ScrollDownBtn section='#section2'/>
        </div>
      </section>

      <Container>
        <section className={classes.infoSection} id='section2'>
          <section className={classes.contentSection}>
            <div style={{display:'flex', justifyContent:'center'}}>
              <FaMapMarkerAlt size={22} color='var(--red-accent)'/>
              <h4 style={{marginInline:'1%'}}>Based in Leicester & coverage through Midlands</h4>
              <FaMapMarkerAlt size={22} color='var(--red-accent)'/>
            </div>

            <h4>Your journey starts here!</h4>

            <Button color='var(--red-accent)' click={goToLearners}>
              <GiGearStick size={33}/>
              <p>Manual & automatic driving lessons</p>
            </Button>

            <Button color='var(--green-main)' click={goToInstructors}>
              <FaAddressCard size={33}/>
              <p>Become a driving instructor</p>
            </Button>

            <Button color='var(--blue-main)' click={goToContact}>
              <FaPhoneAlt size={33}/>
              <p>Contact us now</p>
            </Button>
          </section>

          <section className={classes.contentSection}>
            <h4>Why choose us?</h4>
            <TextCard left={true}>
              <p>Our tried and tested team of driving instructors are top of their class.</p>
              <p>Delivering accurate, efficient and safe driving methods to enhance your driving experience.</p>
            </TextCard>
            <TextCard left={true}>
              <p>Rest-assured, when you join us we will make you into a better and safer driver.</p>
            </TextCard>
            <Button color='var(--red-accent)' click={goToLearners}>
              Click here to learn more
            </Button>
          </section>
        </section>
      </Container>
    </div>
  )
}

export default Home;