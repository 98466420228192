import React from 'react'
import classes from './textCard.module.css'

const textCard = (props) => {
  return (
    <div className={classes.textCard} style={{backgroundColor:props.color, borderColor:props.color}}>
        {props.children}
    </div>
  )
}

export default textCard