import React from 'react'
import classes from './infoCard.module.css'

const infoCard = (props) => {
  return (
    <div className={classes.infoCard} style={{backgroundColor:props.color, borderColor:props.color}}>
        {props.children}
    </div>
  )
}

export default infoCard